/* eslint-disable import/no-unused-modules  */
import { isDemo, isDev, isLocal } from "./utils/api";

const trackUrl = "https://misc.ommej.se/umami/script.js";
const devId = "6dd54313-5374-4181-9bf6-61d3a12dd797";
const demoId = "f36a1118-2886-4600-852b-e324418c603a";
const prodId = "fd0993d6-39c3-4d5f-9f04-89c0621e5b04";

const createScriptTag = (environmentId: string) => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("defer", "");
    script.setAttribute("async", "");
    script.dataset.websiteId = environmentId;
    script.dataset.doNotTrack = "true";
    script.setAttribute("src", trackUrl);
    if (head) {
        head.appendChild(script);
    }
};

const loadTrackerScript = () => {
    if (isLocal) {
        return;
    }
    if (isDev) {
        createScriptTag(devId);
    } else if (isDemo) {
        createScriptTag(demoId);
    } else {
        createScriptTag(prodId);
    }
};

loadTrackerScript();
